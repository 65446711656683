<template>
  <div class="right">
    <span class="spen">我的积分</span>
    <span class="jifen_logo"  @click="jifen"><img width="100%" src="../../../assets/user/jifenshangcheng.png"/></span>
    <div class="yue">
      <div class="yue_L">
        <div class="yue_div">
          <img src="../../../assets/order/zichan/points.png" alt="" />
          <span class="big">{{ Number(scoreTotal) }}</span>
        </div>
      </div>
      <div class="yue_C">
        <div class="quanbu">
          <img src="../../../assets/order/quanbu.png" alt="" />
          <span class="quanbu_Y1">购物返积分：</span>
          <span class="quanbu_Y2">+{{ Number(scoreGw) }}</span>
        </div>
        <div class="quanbu">
          <img src="../../../assets/order/suoding.png" alt="" />
          <span class="quanbu_Y1">注册赠积分：</span>
          <span class="quanbu_Y2">+{{ Number(scoreZc) }}</span>
        </div>
        <div class="quanbu">
          <img src="../../../assets/order/zhanghu_yue.png" alt="" />
          <span class="quanbu_Y1">其他返积分：</span>
          <span class="quanbu_Y2">+{{ Number(scoreQt) }}</span>
        </div>
      </div>
      <div></div>
      <!--            <router-link :to="{ name: 'Chongzhi' }" tag="div" class="yue_R">赚积分</router-link>-->
    </div>
    <!-- <div class="menu">
      <div class="qiehuan">
        <div
          class="daohang"
          :class="[
            { bian: index == TabIndex },
            { 'border-right-line': index != TabList.length - 1 },
          ]"
          v-for="(item, index) in paihang"
          :key="index"
          @click="selectedTabItem(index, item.id)"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="jilu">
        <img
          class="questionImg"
          src="../../../assets/order/zichan/question.png"
          alt=""
        />
        <span class="questionSpan">积分使用常见问题</span>
      </div>
    </div> -->
    <Loading v-if="isLoading" />
    <div v-else>
      <div v-if="TabIndex == 0" class="jifen">
        <div class="huoqu">近三月总获取:{{ huoqu }}</div>
        <div class="huoqu">近三月总支出:{{ zhichu }}</div>
        <el-table :data="data1" style="width: 100%;margin-top:30px">
          <el-table-column prop="createTime" label="时间"></el-table-column>
          <el-table-column
            prop="changeValue"
            label="收入/支出"
          ></el-table-column>
          <el-table-column prop="sourceType" label="积分来源"></el-table-column>
          <el-table-column prop="sourceRemark" label="备注"></el-table-column>
        </el-table>
      </div>
      <div v-if="TabIndex == 1">
        <el-table :data="data2" style="width: 100%">
          <el-table-column prop="createTime" label="时间"></el-table-column>
          <el-table-column
            prop="changeValue"
            label="收入/支出"
          ></el-table-column>
          <el-table-column prop="sourceType" label="积分来源"></el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination
      @current-change="pagechange"
      :current-page="page.current"
      style="text-align: right;margin-top: 20px"
      background
      :total="page.total"
      :page-size="page.size"
      layout="prev, pager, next, jumper, ->, total"
    >
    </el-pagination>
    <div class="tishi">
      提示：系统仅显示您三个月之内的积分明细，更早的积分明细不再显示。
    </div>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      paihang: [
        {
          id: 1,
          title: '近三个月积分明细',
        },
        {
          id: 2,
          title: '近一年积分明细',
        },
      ], // Tab页
      TabIndex: 0, // 默认选中Tab
      TabList: [], // Tab数据集合
      data1: [], // 近三个月积分数据
      data2: [], // 近一年积分数据
      page: {
        total: 1,
        size: 10,
        current: 1,
      }, // 分页
      scoreTotal: 0, // 总积分
      scoreZc: 0, // 注册赠送
      scoreGw: 0, // 购物赠送
      scoreQt: 0, // 签到赠送
      isLoading: true,
      huoqu: 0,
      zhichu: 0,
    };
  },
  created() {
    this.getdata1(); // 近三个月积分数据
    this.getScore(); // 获取会员积分
    this.getScoreTotal(); // 按积分来源统计
    this.gethuoqu();
    this.getzhichu();
  },
  methods: {
    jifen() {
      this.$router.push({
        name: 'Integral',
      });
    },
    gethuoqu() {
      get('api/account/getScoreRecordBySourceType?sourceType=0').then((res) => {
        if (res.data.msg == '操作成功') {
          this.huoqu = res.data.data;
        }
      });
    },
    getzhichu() {
      get('api/account/getScoreRecordBySourceType?sourceType=1').then((res) => {
        if (res.data.msg == '操作成功') {
          this.zhichu = res.data.data;
        }
      });
    },
    // 获取会员积分
    getScore() {
      // 账户积分
      get('api/account/selAccount').then((res) => {
        if (res.data.data.scoreCount != null) {
          this.scoreTotal = res.data.data.scoreCount;
        } else {
          this.scoreTotal = 0;
        }
      });
    },
    // 按积分来源统计
    getScoreTotal() {
      get('api/account/selAccountScoreTotal').then((res) => {
        res.data.data.forEach((req) => {
          // 积分来源(0注册,1订单,2签到,3兑换)
          if (req.sourceType == '0') {
            this.scoreZc = req.changeValue;
          } else if (req.sourceType == '1') {
            this.scoreGw = req.changeValue;
          } else if (req.sourceType == '2') {
            this.scoreQt = req.changeValue;
          }
        });
      });
    },
    // 分页
    pagechange(p) {
      this.isLoading = true;
      this.page.current = p;
      if (this.TabIndex == 0) {
        get(
          'api/account/getScoreRecordList?timeType=0&pageNum=' +
            p +
            '&pageSize=10'
        ).then((res) => {
          res.data.data.list.forEach((req) => {
            if (req.sourceType == 0) {
              req.sourceType = '注册';
            }
            if (req.sourceType == 1) {
              req.sourceType = '订单';
            }
            if (req.sourceType == 2) {
              req.sourceType = '签到';
            }
            if (req.sourceType == 3) {
              req.sourceType = '兑换';
            }
            if (req.sourceType == 4) {
              req.sourceType = '赠送';
            }
            if (req.changeType == 0) {
              req.changeValue = '+' + req.changeValue;
            }
            if (req.changeType == 1) {
              req.changeValue = '-' + req.changeValue;
            }
          });
          this.data1 = res.data.data.list;
          this.isLoading = false;
        });
      } else if (this.TabIndex == 1) {
        get(
          'api/account/getScoreRecordList?timeType=1&pageNum=' +
            p +
            '&pageSize=10'
        ).then((res) => {
          res.data.data.list.forEach((req) => {
            if (req.sourceType == 0) {
              req.sourceType = '注册赠送';
            }
            if (req.sourceType == 1) {
              req.sourceType = '购物返赠';
            }
            if (req.sourceType == 2) {
              req.sourceType = '签到赠送';
            }
            if (req.sourceType == 3) {
              req.sourceType = '兑换商品';
            }
            if (req.sourceType == 4) {
              req.sourceType = '赠送';
            }
            if (req.changeType == 0) {
              req.changeValue = '+' + req.changeValue;
            }
            if (req.changeType == 1) {
              req.changeValue = '-' + req.changeValue;
            }
          });
          this.data2 = res.data.data.list;
          this.isLoading = false;
        });
      }
    },
    // 获取近三个月积分数据
    getdata1() {
      get(
        'api/account/getScoreRecordList?timeType=0&pageNum=1&pageSize=10'
      ).then((res) => {
        res.data.data.list.forEach((req) => {
          if (req.sourceType == 0) {
            req.sourceType = '注册赠送';
          }
          if (req.sourceType == 1) {
            req.sourceType = '购物返赠';
          }
          if (req.sourceType == 2) {
            req.sourceType = '签到赠送';
          }
          if (req.sourceType == 3) {
            req.sourceType = '兑换商品';
          }
          if (req.changeType == 0) {
            req.changeValue = '+' + req.changeValue;
          }
          if (req.changeType == 1) {
            req.changeValue = '-' + req.changeValue;
          }
        });
        this.data1 = res.data.data.list;
        this.isLoading = false;
        if (this.TabIndex == 0) {
          this.page.total = res.data.data.total;
        }
      });
    },
    // 获取近一年积分数据
    getdata2() {
      get(
        'api/account/getScoreRecordList?timeType=1&pageNum=1&pageSize=10'
      ).then((res) => {
        res.data.data.list.forEach((req) => {
          if (req.sourceType == 0) {
            req.sourceType = '注册赠送';
          }
          if (req.sourceType == 1) {
            req.sourceType = '购物返赠';
          }
          if (req.sourceType == 2) {
            req.sourceType = '签到赠送';
          }
          if (req.sourceType == 3) {
            req.sourceType = '兑换商品';
          }
          if (req.changeType == 0) {
            req.changeValue = '+' + req.changeValue;
          }
          if (req.changeType == 1) {
            req.changeValue = '-' + req.changeValue;
          }
        });
        this.data2 = res.data.data.list;
        this.isLoading = false;
        if (this.TabIndex == 1) {
          this.page.total = res.data.data.total;
        }
      });
    },
    // Tab页改变事件
    selectedTabItem(index, e) {
      this.TabIndex = index;
      this.page.current = 1;
      if (this.TabIndex == 0) {
        this.getdata1();
      }
      if (this.TabIndex == 1) {
        this.getdata2();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.jifen {
  margin-top: 30px;
  .huoqu {
    margin-top: 10px;
    color: #666;
  }
}

.right {
  width: 976px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  padding-right: 30px;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .jifen_logo{
    width: 20%;
    font-size: 26px;
    font-weight: 600;
    float: right;
    cursor: pointer;
  }
  .jifen_logo img{
    opacity: 0.7;
  }
  .jifen_logo:hover img{
    opacity: 1;
  }
  .yue {
    width: 100%;
    height: 130px;
    margin-top: 63px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .yue_L {
      font-size: 26px;
      color: #777;
      font-weight: 600;
      .yue_div {
        margin-right: 100px;
        img {
          display: block;
          margin: 0 auto;
        }
        .big {
          font-size: 46px;
          display: block;
          margin: 0 auto;
          text-align: center;
        }
      }
      border-right: 1px solid #eee;
      width: 40%;
    }
    .yue_C {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .quanbu {
        display: flex;
        font-size: 16px;
        img {
          width: 20px;
          height: 20px;
        }
        .quanbu_Y1 {
          color: #333;
          margin-left: 15px;
        }
        .quanbu_Y2 {
          color: #545454;
          margin-left: 20px;
        }
      }
    }
    .yue_R {
      width: 112px;
      height: 38px;
      background: #1850ff;
      color: #fff;
      line-height: 38px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .menu {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    .qiehuan {
      margin-top: 60px;
      height: 42px;
      display: flex;
      align-items: center;
      cursor: default;
      .daohang {
        font-size: 15px;
        color: #272727;
        margin-right: 25px;
        cursor: pointer;
      }
      .bian {
        color: #1a4fff;
      }
    }
    .jilu {
      margin-top: 60px;
      display: flex;
      align-items: center;
      .questionImg {
        margin-right: 5px;
        width: 20px;
        height: 20px;
      }
      .questionSpan {
        font-size: 14px;
        color: #909399;
      }
    }
  }
  .tishi {
    font-size: 14px;
    color: #929292;
    margin-top: 10px;
  }
}
</style>
